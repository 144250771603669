import type { UserAbility } from '@/plugins/casl/AppAbility'

export const adminAbilities: UserAbility[] = [
  // Auth
  {
    action: 'read',
    subject: 'Auth'
  },

  {
    action: 'operate',
    subject: 'SmsMessagesManagment'
  },
  {
    action: 'read',
    subject: 'SmsMessagesManagment'
  },
  {
    action: 'read',
    subject: 'mainView'
  },
  {
    action: 'read',
    subject: 'CustomersManagement'
  },
  {
    action: 'read',
    subject: 'SitesManagement'
  }
]
