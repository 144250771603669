import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useAuth } from '@/composables/auth'
import DefaultLayout from '@/layout/DefaultLayout.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL || '/'),
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 10 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: () => {
        return { name: 'mainView' }
      },
      name: 'admin',
      component: () => import('@/views/AdminView.vue'),
      children: [
        {
          path: '/mainView',
          name: 'mainView',
          meta: {
            layout: DefaultLayout,
            subject: 'mainView'
          },
          component: () => import('@/views/mainView/MainView.vue')
        },
        {
          path: '/smsCustomersPageView',
          name: 'smsCustomersPageView',
          meta: {
            layout: DefaultLayout,
            subject: 'SmsMessagesManagment'
          },
          component: () => import('@/components/smsMessagesManagment/smsCustomersPage.vue')
        },
        {
          path: '/customers',
          name: 'customers',
          meta: {
            layout: DefaultLayout,
            subject: 'CustomersManagement'
          },
          component: () => import('@/views/customers/CustomersPageView.vue')
        },

        {
          path: '/customers/:id',
          name: 'customer',
          meta: {
            layout: DefaultLayout,
            subject: 'CustomersManagement'
          },
          component: () => import('@/views/customers/CustomerView.vue')
        },
        {
          path: '/sitesPageView',
          name: 'sitesPageView',
          meta: {
            layout: DefaultLayout,
            subject: 'SitesManagement'
          },
          component: () => import('@/views/sites/SitesPageView.vue')
        },
        {
          path: '/sites/:id',
          name: 'site',
          meta: {
            layout: DefaultLayout,
            subject: 'SitesManagement'
          },
          component: () => import('@/views/sites/SiteView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
      beforeEnter: () => {
        const { accessToken } = useAuth()
        if (accessToken) {
          return { name: 'admin' }
        }
      }
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () => import('@/views/NotFoundView.vue')
    }
  ]
})
router.beforeEach(async (to, _from) => {
  const { get } = useCookies(['token'])
  const sessionToken = get('token')

  // Redirect to login if not authenticated
  if (!sessionToken && to.name !== 'login') {
    return { name: 'login' }
  }

  // // Proceed if the user is authenticated
  // if (sessionToken) {
  //   const { can } = useAbility()
  //   const routeMeta = to.meta.subject || to.name // Determine subject from route meta or name
  //   // Check if the user has the ability to access the route
  //   console.log(routeMeta, can('read', routeMeta as string))
  //   console.log(useAbility())
  //   if (!can('read', routeMeta as string)) {
  //     return { name: 'notFound' }
  //   }
  // }
})

export default router
