import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { adminAbilities } from '@/abilities/admin'

import type { UserAbility } from '@/plugins/casl/AppAbility'

type User = {
  id: string
  email: string
  nicename: string
  firstName: string
  role: string
  lastName: string
  displayName: string
  token: string
}

interface UserState {
  user: Ref<User | null>
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: ref(null)
  }),
  actions: {
    getUserAbilities(userRole: string): UserAbility[] {
      if (userRole === 'administrator') {
        return [...adminAbilities]
      }
      return []
    },
    getUserStoredAbilities() {
      const userAbilities = localStorage.getItem('userAbilities')
      if (userAbilities) {
        return JSON.parse(userAbilities)
      } else {
        return null
      }
    },
    setUser(user: User | null) {
      this.user = user
      if (user?.role) {
        const userAbilities = this.getUserAbilities(user.role)
        localStorage.setItem('userAbilities', JSON.stringify(userAbilities))
        return {
          success: true,
          abilities: userAbilities
        }
      }
      return {
        success: false,
        abilities: null
      }
    }
  },
  persist: true
})
