<script setup lang="ts">
import { breakpointsVuetify, useWindowSize, type MaybeRef } from '@vueuse/core'
import { computed, unref, ref, onMounted } from 'vue'
import SideNavBar from '@/components/SideNavBar.vue'
import { useDisplay } from 'vuetify'

const { smAndDown } = useDisplay()
const { width: windowWidth } = useWindowSize()

const navController = ref(true)

const isLessThanOverlayNavBreakpoint = computed(() => {
  return (windowWidth: MaybeRef<number>) => unref(windowWidth) < breakpointsVuetify.sm
})
function controllNavToggle() {
  navController.value = !navController.value
}
onMounted(() => {
  if (smAndDown.value) {
    navController.value = false
  }
})
</script>

<template>
  <v-layout class="rounded rounded-md overflow-hidden">
    <SideNavBar v-model="navController" />
    <v-locale-provider locale="ar">
      <v-app-bar
        style="position: fixed; display: flex; justify-content: space-between"
        class="mb-12"
        v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
      >
        <div
          style="
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="bg-black h-100 flex pa-2">
            <img src="@/assets/images/login-background.png" class="bg-black" style="width: 120px" />
          </div>
          <v-app-bar-nav-icon @click="controllNavToggle"> </v-app-bar-nav-icon>
        </div>
      </v-app-bar>
    </v-locale-provider>

    <v-main style="height: 100vh; overflow-y: scroll" class="pt-4 mt-12 mt-md-0">
      <div class="pa-4">
        <slot />
      </div>
    </v-main>
  </v-layout>
</template>
<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.container {
  position: relative;
  width: 56px;
  height: 85px;
}

.vertical-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #333;
  content: '';
}
.point {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
}
@media screen and (min-width: 700) {
}
</style>
