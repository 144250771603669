const navigationItems = [
  {
    id: 1,
    title: 'الرئيسية',
    key: 'mainView',
    ability: 'mainView',
    to: '/mainView',
    icon: { icon: 'mdi mdi-home' },
    subItems: <any>[]
  },
  {
    id: 2,
    title: 'اعدادات الرسائل',
    key: 'smsMessagesManagment',
    ability: 'SmsMessagesManagment',
    to: '/smsCustomersPageView',
    icon: { icon: 'mdi mdi-shape' },
    subItems: <any>[]
  },
  {
    id: 3,
    title: 'ادارة العملاء',
    key: 'customersManagement',
    ability: 'CustomersManagement',
    to: '/customers',
    icon: { icon: 'mdi mdi-account-group' },
    subItems: <any>[]
  },
  {
    id: 4,
    title: 'ادارة المواقع',
    key: 'sitesManagement',
    ability: 'SitesManagement',
    to: '/sitesPageView',
    icon: { icon: 'mdi mdi-map-marker' },
    subItems: <any>[]
  }
]

export default navigationItems
